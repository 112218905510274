export const useAuthenticationDataStore = defineStore(
  "authenticationData",
  () => {
    const initialized = ref(false);
    const { data: authenticationData, refresh } = useApi(
      "/api/authenticationData",
      {
        immediate: false,
        method: "get",
      },
    );

    async function initialize() {
      if (!initialized.value) {
        initialized.value = true;
        await refresh();
      }
    }

    return {
      initialize,
      authenticationData,
    };
  },
);
