export async function useAuthenticationData() {
  const authenticationDataStore = useAuthenticationDataStore();
  const { initialize } = authenticationDataStore;
  const { authenticationData } = storeToRefs(authenticationDataStore);
  const config = useRuntimeConfig();
  const url = useRequestURL();

  await initialize();

  function goToMM() {
    if (
      !authenticationData.value ||
      !authenticationData.value.options.mmLoginOnly
    ) {
      return;
    }
    if (config.public.mockServicesEnabled && config.public.mockServicesUrl) {
      return navigateTo(`${config.public.mockServicesUrl}/mmSimulator`, {
        external: true,
      });
    } else if (config.public.mockServicesEnabled) {
      return navigateTo(
        `${url.protocol}//${url.host}/mock-services/mmSimulator`,
        {
          external: true,
        },
      );
    }
    return navigateTo(authenticationData.value?.options.mmHomePage, {
      external: true,
    });
  }

  return { authenticationData, goToMM };
}
